import { v2Colors, getRgbaColor, breakpoints } from '@web-for-marketing/react-ui';

export const searchDropdownStyles = {
    searchIconSvg: {
        '& path': {
            fill: v2Colors.core.innovation,
        },
    },
    searchContainer: {
        width: '100vw',
        zIndex: -1,
        transform: 'translateY(0)',
        position: 'absolute',
        bottom: 0,
        right: 0,
        opacity: 0,
        transition: 'transform 300ms ease, z-index 300ms step-start, opacity 300ms ease',
        padding: '0 20rem',
        background: `linear-gradient(90deg, ${v2Colors.core.innovation} 0%, ${v2Colors.core.geotab} 100%)`,
        '&:before': {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '1.5rem solid transparent',
            borderRight: '1.5rem solid transparent',
            borderBottom: `1.5rem solid ${v2Colors.core.geotab}`,
            marginTop: '-1.4rem',
            position: 'absolute',
            right: '4rem',
            transform: 'scaleY(0)',
            transition: 'transform 300ms ease 250ms',
            transformOrigin: 'bottom',
        },
        '@media (max-width: 1200px)': {
            padding: '0 8rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            flexDirection: 'column',
            padding: '0 3rem',
            background: `linear-gradient(${v2Colors.core.innovation} 0%, ${v2Colors.core.geotab} 100%)`,
            minHeight: '90vh',
            justifyContent: 'space-between',
            '&:before': {
                borderBottom: `1.5rem solid ${v2Colors.core.innovation}`,
            },
        },
        '@media (max-width: 374.9px)': {
            '&:before': {
                right: '3rem',
            },
        },
    },
    showOpen: {
        zIndex: 900,
        opacity: 1,
        transform: 'translateY(100%)',
        transition: 'transform 300ms ease, z-index 300ms step-start 300ms, opacity 300ms ease',
        '&:before': {
            transform: 'scaleY(1)',
        },
    },
    searchBarContainer: {
        zIndex: 2,
        width: '60%',
        padding: '8rem 0',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            width: '100%',
            margin: '4rem 0',
        },
    },
    popularTopicsContainer: {
        zIndex: 2,
        width: '40%',
        padding: '8rem 0',
        paddingLeft: '6.4rem',
        '@media (max-width: 1200px)': {
            paddingLeft: '10rem',
        },
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: 0,
            width: '100%',
        },
    },
    searchText: {
        color: v2Colors.core.snow,
        marginBottom: '2rem',
    },
    linkContainer: {
        marginBottom: '1.6rem',
        width: '50%',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            width: '100%',
            marginBottom: '3.2rem',
        },
    },
    popularLink: {
        textDecoration: 'none',
        '&:hover > p': {
            fontWeight: 700,
        },
    },
    background: {
        display: 'block',
        transition:
            'z-index 300ms step-start 300ms, visibility 300ms ease 300ms, opacity 300ms ease 300ms, height 0ms ease 600ms',
        visibility: 'hidden !important' as 'hidden',
        opacity: 0,
        zIndex: -1,
        height: 0,
        width: '100%',
        backgroundColor: getRgbaColor(v2Colors.core.deepSpace, 0.7),
        position: 'absolute',
        top: '100%',
        left: 0,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none !important',
        },
    },
    showBackground: {
        zIndex: 800,
        opacity: 1,
        visibility: 'visible !important' as 'visible',
        transition: 'z-index 300ms step-end 300ms, visibility 300ms ease 300ms, opacity 300ms ease 300ms',
        height: '100vh',
    },
    searchIcon: (shrink?: boolean) =>
        ({
            padding: '1rem',
            '& svg': {
                height: shrink ? '1.2rem' : '1.6rem',
                width: shrink ? '1.2rem' : '1.6rem',
                [`@media (max-width: ${breakpoints.md}px)`]: {
                    height: '2.3rem',
                    width: '2.3rem',
                },
            },
        }) as const,
    searchImage: {
        display: 'block',
        position: 'absolute',
        bottom: 0,
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'none',
        },
    },
    searchMobileImage: {
        display: 'none',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            display: 'block',
            width: '100%',
        },
    },
} as const;
