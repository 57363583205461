import React, { useState, useRef, useEffect, useMemo, startTransition } from 'react';
import { PublicSearchBar } from './PublicSearchBar';
import { Grid, IconButton, ClickAwayListener } from '@mui/material';
import { Heading, SearchIcon, Text } from '@web-for-marketing/react-ui';
import { useAccessiblePopout } from '@web-for-marketing/react-ui';
import { searchDropdownStyles as classes } from './styles/searchDropdownStyles';
import { useAtom } from 'jotai';
import { isMobileAtom, selectedLanguageAtom } from '@atoms/appSettings';
import { useTranslation } from '@stateManagement/TranslationContext';
import { getTranslation } from '@helpers/languageTranslations';
import searchBackground from '@images/search-background.svg';
import { CMSLink } from '@components/CMSLink';
import { LanguageCode } from '@models/languageCode';

interface PublicSearchDropdownProps {
    preview?: boolean;
    shrink?: boolean;
}

type PopularTopicsLink = {
    href: string;
    title: string;
    target?: string;
};

export function PublicSearchDropdown({ preview, shrink }: PublicSearchDropdownProps): JSX.Element {
    const [open, setOpen] = useState(false);
    const dropdown = useRef<HTMLDivElement>(null);
    useAccessiblePopout(dropdown, open, close, { focusedElementIndex: 1 });
    const images = dropdown.current?.querySelectorAll('img[data-src]');
    const [isMobile] = useAtom(isMobileAtom);
    const [selectedLanguage] = useAtom(selectedLanguageAtom);
    const { selectedLanguageTranslation } = useTranslation();
    const searchTranslation = useMemo(
        () => getTranslation(selectedLanguageTranslation, 'search'),
        [selectedLanguageTranslation]
    );
    const popularTopics = useMemo(
        () => getTranslation(selectedLanguageTranslation, 'search', 'popularTopics'),
        [selectedLanguageTranslation]
    );

    useEffect(() => {
        if (open && images?.length) {
            images.forEach((image) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                image.setAttribute('src', image.dataset.src);
                image.removeAttribute('data-src');
            });
        }
    }, [open, images]);

    useEffect(() => {
        close();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    function toggleSearch(): void {
        startTransition(() => {
            setOpen((curValue) => !curValue);
        });
    }

    function close(): void {
        open && setOpen(false);
    }

    return (
        <>
            {preview ? (
                <IconButton css={classes.searchIcon(shrink)} id='searchButton' size='large' tabIndex={-1}>
                    <SearchIcon aria-labelledby='searchButton' css={classes.searchIconSvg} />
                </IconButton>
            ) : (
                <>
                    <ClickAwayListener onClickAway={close}>
                        <div ref={dropdown}>
                            <IconButton
                                aria-label={open ? 'Close search' : 'Open search'}
                                onClick={toggleSearch}
                                css={classes.searchIcon(shrink)}
                                data-testid='publicSearchButton'
                                id='searchButton'
                                size='large'
                            >
                                <SearchIcon aria-labelledby='searchButton' css={classes.searchIconSvg} />
                            </IconButton>
                            <Grid
                                container
                                wrap='nowrap'
                                role='dialog'
                                css={[classes.searchContainer, open ? classes.showOpen : '']}
                                data-testid='publicSearchDropdown'
                                aria-labelledby='search-bar-title'
                                aria-hidden={!open}
                            >
                                <img
                                    src=''
                                    data-src={searchBackground}
                                    alt='A graphic of a graph'
                                    css={classes.searchImage}
                                />
                                <Grid css={classes.searchBarContainer}>
                                    <Heading variant='h2' component='p' css={classes.searchText} id='search-bar-title'>
                                        {searchTranslation.search}
                                    </Heading>
                                    {open ? <PublicSearchBar /> : null}
                                </Grid>
                                {selectedLanguage === LanguageCode.en ? (
                                    <Grid css={classes.popularTopicsContainer}>
                                        <Heading variant='h4' component='p' css={classes.searchText}>
                                            {popularTopics.title}
                                        </Heading>
                                        <Grid container>
                                            {popularTopics.links.map((link: PopularTopicsLink) => (
                                                <div css={classes.linkContainer} key={link.title}>
                                                    <CMSLink
                                                        href={link.href}
                                                        css={classes.popularLink}
                                                        type='link'
                                                        target={link.target}
                                                        tabIndex={open ? 0 : -1}
                                                    >
                                                        <Text variant='body1' color='inversePrimary'>
                                                            {link.title}
                                                        </Text>
                                                    </CMSLink>
                                                </div>
                                            ))}
                                        </Grid>
                                    </Grid>
                                ) : null}
                                <img
                                    src=''
                                    data-src={searchBackground}
                                    alt='A graphic of a graph'
                                    css={classes.searchMobileImage}
                                />
                            </Grid>
                        </div>
                    </ClickAwayListener>
                    <div
                        css={[classes.background, open ? classes.showBackground : '']}
                        data-testid='publicSearchbackground'
                    />
                </>
            )}
        </>
    );
}
